// global style
@import './variables.scss';

.content {
  padding: 1rem;
  height: 100%;
  width: 100%;
  max-width: 100vw;
}

.main {
  width: 100%;
  min-height: 100%;
  background-color: #fff;
  padding: 2rem;
}

.no-wrap {
  white-space: nowrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}

.fs-125 {
  font-size: 1.25rem !important;
}
.fs-150 {
  font-size: 1.5rem !important;
}

.mt-5 {
  margin-top: 5px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-5 {
  margin-right: 5px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.self-center {
  margin: 0 auto;
}

.self-end {
  justify-self: end;
}

.width-fit {
  width: fit-content;
}

.pointer {
  cursor: pointer;
}

.bg-pasblue {
  min-height: 35px;
  min-width: 70px;
  border-radius: 5px;
  background-color: $blue-pastel !important;
  border: none;
  border-radius: 5px !important;
}

.outline-btn {
  min-height: 35px;
  min-width: 70px;
  border-radius: 5px;
  border: 2px solid $blue-pastel;
  color: $blue-pastel !important;
  &:hover {
    border-color: $blue-pastel;
  }
}

.button {
  border-radius: 12px !important;
  min-width: 5rem !important;
  height: 2.5rem !important;
}

.h-2rm {
  height: 2rem !important;
}

.width-100 {
  width: 100% !important;
}

.width-50 {
  width: 50%;
}

.width-33 {
  width: 33%;
}

.width-20 {
  width: 20%;
}

.width-80 {
  width: 80%;
}

.width-66 {
  width: 66%;
}

.w-200 {
  width: 200px !important;
}

.w-150 {
  width: 150px !important;
}

.w-120 {
  width: 120px !important;
}

.d-flex {
  display: flex;
}

.j-center {
  justify-content: center;
}

.j-end {
  justify-content: flex-end;
}

.al-start {
  align-items: flex-start;
}

.al-center {
  align-items: center;
}

.al-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-columns {
  flex-direction: column;
}

.space-bt {
  justify-content: space-between;
}
.label {
  width: 90px;
}

.info-dot {
  border-bottom: 1px dotted $gray3;
  min-height: 2.5rem;
  padding: 10px 5px 5px 0px;
  width: calc(100% -10px);
  height: fit-content;
}

.info-title {
  border-left: 3px solid $gray4;
  padding-left: 5px;
  height: fit-content;
}

.input {
  outline: none;
  height: 35px;
  border-radius: 5px !important;
  width: 150px;
}

.input-custom {
  outline: none;
  height: 35px;
  border-radius: 5px !important;
  width: 150px;
  border: 1px solid $gray3;
  padding-left: 10px;
}

.ant-select-selector {
  height: 35px !important;
  border-radius: 5px !important;
}

.table_icon {
  color: #000;
  height: 24px;
  cursor: pointer;
}

.search-input {
  border: 1px solid $gray3;
  outline: none;
  min-height: 30px;
  border-radius: 2px;
  max-width: 150px;
  &:focus {
    box-shadow: 0 0 2px rgba(24, 144, 255, 0.2);
  }
}

.table_icon {
  color: #000;
  height: 26px;
}

.ant-menu {
  background: #fff;
}

.ant-menu-item-selected a {
  color: $blue-pastel !important;
  ::after {
    border-right: 3px solid $blue-pastel !important;
  }
}
.ant-menu-item-selected {
  &:after {
    border-right: 3px solid $blue-pastel !important;
  }
}

.ant-menu-item > a {
  &:hover {
    color: $blue-pastel !important;
  }
}

.ant-table-container {
  width: 100% !important;
}
.ant-table {
  border-radius: 4px;
}

.ant-table-row {
  width: 100%;
  background-color: #fff;
}

.ant-table-cell {
  padding: 12px 12px !important;
}

.box-sd {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.125);
}

.font-24 {
  font-size: 24px;
}

.ant-pagination {
  text-align: center;
  padding: 0.5rem 0 !important;
}

.ant-table-wrapper {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.125);
  overflow-x: auto;
  overflow-y: hidden;
  border-radius: 5px;
}

.ant-table-thead > tr > th {
  background-color: $gray7;
  white-space: nowrap;
  font-weight: 600;
  height: 50px;
}

.ant-table-tbody > tr > td {
  background-color: #fff;
}

.ant-modal {
  width: fit-content !important;
  min-width: 500px;
}

.ant-modal-header {
  text-align: center;
}

.ant-modal-title {
  color: $blue-pastel !important;
  font-weight: 400;
  font-size: 130%;
}

.ant-pagination-item-active {
  border-color: $blue-pastel !important;
  background-color: $blue-pastel !important;
  border-radius: 4px !important;
  > a {
    color: #fff !important;
    &:hover {
      color: #fff !important;
    }
  }
}

.ant-pagination-item > a {
  &:hover {
    color: $blue-pastel;
  }
}

.ant-form-item {
  margin-bottom: 0 !important;
  // margin-top: 5px !important;
}
.ant-tabs-nav-wrap {
  border-bottom: 1px solid $gray2;
}

.ant-tabs-tab {
  padding: 8px 12px !important;
  margin: 0 !important;
  .ant-tabs-tab-btn {
    color: #000 !important;
  }
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  background: $blue-pastel !important;
  border-bottom: none;
  .ant-tabs-tab-btn {
    color: #fff !important;
  }
}

.ant-menu-item {
  width: 90% !important;
  margin: 0 auto;
  border-radius: 5px;
  a {
    color: #000 !important;
    font-weight: 400 !important;
  }
  .anticon {
    color: #000 !important;
  }
}

.ant-menu-item-selected {
  background-color: $blue-pastel !important;
  color: #fff !important;
  border-radius: 5px;
  a {
    color: #fff !important;
    font-weight: 550;
  }
  .anticon {
    color: #fff !important;
  }
  &:after {
    display: none;
  }
}

.ant-menu-item-active {
  color: unset !important;
  .anticon {
    color: unset;
  }
}

.no-image {
  margin: 10px auto;
  width: 60%;
}

.ant-radio-checked .ant-radio-inner::after {
  border-radius: 50%;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height: 34px !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
}

.ant-modal-centered .ant-modal {
  width: fit-content !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: hidden;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $blue-pastel !important;
  border-color: $blue-pastel !important;
  &:after {
    margin-top: -1px;
    margin-left: -1px;
  }
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  display: none;
}

.ant-checkbox-inner {
  height: 25px;
  width: 25px;
  margin-bottom: 4px;
  &:after {
    width: 10px;
    height: 17.6px;
    border-color: #fff !important;
  }
}

.ant-btn[disabled] {
  background: #ccc !important;
}

@media screen and (max-width: $max-width-sp) {
  .no-image {
    width: 100%;
  }
  .ant-modal {
    width: fit-content !important;
    min-width: 300px;
  }
}

@media screen and (max-width: $max-width-tablet) {
  .ant-pagination-options {
    display: unset;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.anticon-ellipsis > svg {
  display: none;
}
.ant-tabs-nav-more {
  position: relative;
}
.ant-tabs-nav-more::before {
  position: absolute;
  content: '»';
  font-size: 40px;
  display: block;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.5s infinite ease-in-out;
  animation: load7 1.5s infinite ease-in-out;
}
.loader {
  color: $gray1;
  font-size: 4px;
  margin: 12px 12px auto;
  position: relative;
  text-indent: -9999em;
  top: -16px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 3.5em;
}
@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
