@import '../../styles/variables.scss';

.pageWrapper {
  height: 100vh;
  display: flex;
}
.mainWrapper {
  height: 100%;
  flex: 1;
  background-color: $gray-background;
  width: calc(100% - 220px);
}
.pageContent {
  flex: 1;
  overflow: auto;
  height: calc(100vh - 60px);
}
