$blue-pastel: #2c387e;
$gray1: #aaaaaa;
$gray2: #cecece;
$gray3: #ccc;
$gray4: #504d4d;
$gray5: #ebe9e9;
$gray6: #f7f7fa;
$gray7: #fafafa;
$gray-background: #efefef;

$brown: #c79b60;
$green1: #82e733;
$red1: #fe0000;
$brown90: rgba(199, 155, 96, 0.9);
$brown2: #fff8ea;

$max-width-sp: 576px;
$min-width-tablet: 577px;
$max-width-tablet: 768px;
$min-width-pc: 769px;
